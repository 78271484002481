import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { IndexQueryQuery, PostByPathQuery } from '../../types/graphql-types'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import { Link } from 'gatsby'
import Video from 'components/video'
import BackgroundImage from 'gatsby-background-image'
import OGImage from 'images/the-dentists-at-gateway-crossing.jpg'
import Logo from 'images/logo.png'
import { Helmet } from 'react-helmet'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const BlogPostThreePage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const overview_img = data.overview_img?.childImageSharp?.fluid

  return (
    <Layout location={location}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>
          Overview Of Dental Implants- The Dentists At Gateway Crossing
        </title>
        <meta
          name="description"
          content="Do you know which tooth replacement options are right for you? Each patient is different which is why it’s important to know the pros and cons of dental implants vs. dentures."
        />
        <meta
          name="robots"
          content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="McCordsville Dentist Office The Dentists At Gateway Crossing"
        />
        <meta
          property="og:description"
          content="Do you know which tooth replacement options are right for you? Each patient is different which is why it’s important to know the pros and cons of dental implants vs. dentures."
        />
        <meta property="og:url" content="https://thedentistsatgc.com/" />
        <meta
          property="og:site_name"
          content="The Dentists At Gateway Crossing"
        />
        <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
        <meta property="og:image" content={OGImage} />
        <meta property="og:image:secure_url" content={OGImage} />
        <meta property="og:image:width" content="1080" />
        <meta property="og:image:height" content="1080" />
        <meta property="og:image:alt" content="McCordsville Dentist" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="McCordsville Dentist Office The Dentists At Gateway Crossing"
        />
        <meta
          name="twitter:description"
          content="Do you know which tooth replacement options are right for you? Each patient is different which is why it’s important to know the pros and cons of dental implants vs. dentures."
        />
        <meta name="twitter:image" content={OGImage} />
        <meta
          name="google-site-verification"
          content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM"
        />
        <meta
          name="google-site-verification"
          content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA"
        />
        <meta name="msapplication-TileImage" content={Logo} />
        <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
      </Helmet>
      <section id="blog-post-section">
        <Container>
          <div className="col-lg-12">
            <h1>Differences Between Dental Implants and Dentures</h1>
            <ul className="list-inline">
              <li className="list-inline-item article-time">2021/08/12</li>
              <li className="list-inline-item badge badge-primary">
                Dental Implants
              </li>
              <li className="list-inline-item badge badge-secondary">
                Dentures
              </li>
            </ul>
            <Img fluid={overview_img} className="blog-post-img" />
            <p>
              Do you know which tooth replacement options are right for you?
              Each patient is different which is why it’s important to know the
              pros and cons of each. We are here to help you make an informed
              decision about your dental treatment. For more information,
              contact our office today to request a consultation with our
              dentist in McCordsville, IN.
            </p>
            <p>
              <h2 className="mb-3">Dentures</h2>
              DeDentures are an appliance that replaces missing tooth function
              and appearance. Depending on your tooth loss and various needs,
              the dentist may recommend a partial or full denture. A full
              denture replaces a full arch of teeth, whereas a partial denture
              only replaces a few teeth. ntal Implants are considered a safe
              method of tooth restoration when performed by a dentist or oral
              surgeon. Dental implant surgery is a common surgery in the United
              States and is growing in popularity. It is seen by most dentists
              and patients as the most effective and permanent solution for
              replacing missing teeth, either individually, or in groups of
              teeth. Dentures rest in your mouth on top of your gums, and you
              should take them out before bedtime so your gums have time to
              recover. Dentures will need to be submerged in water or denture
              cleanser so they don't dry out, crack, or warp.
            </p>
            <h3 className="mb-3">Dentures Types</h3>
            <p>
              Different denture options are available, such as immediate,
              conventional full dentures, and removable partial dentures. Let’s
              briefly run through each type.
            </p>
            <p>
              Immediate Dentures: These types of dentures are usually temporary
              and used for transitional periods. They are often placed right
              after tooth extraction. These may not fit as well because of the
              muscular readjustment that is required and the natural shrinkage
              of gums.
            </p>
            <p>
              Conventional Full Dentures: These are a more long term solution.
              They conform to your gums and mouth. They are made to look like
              your natural teeth. They can also assist in chewing food to a
              large degree although they are not as effective at this as natural
              teeth or dental implants.
            </p>
            <p>
              Removable Partial Dentures: Also known as RPDs, dentures are
              typically made of a resin or plastic base and include false teeth
              that are placed in a custom-made model of your mouth, and come
              from the lab.
            </p>
            <h4>Dental Implants</h4>
            <p>
              Dental implants are an artificial root (usually made of titanium)
              that’s fused in your jaw in order to support a crown. Many
              patients favor dental implants over dentures because they are
              similar in appearance and feel to natural teeth.
            </p>
            <p>
              That means you’ll be able to enjoy many of your favorite foods
              without the worry of ill-fitting dentures which are not as easy to
              use for chewing. Brushing and flossing are necessary for
              maintaining dental implants just as you maintain natural teeth.
            </p>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default BlogPostThreePage

export const query = graphql`
  query BlogPostThreePageQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    overview_img: file(name: { eq: "dental-implants-blog3" }) {
      childImageSharp {
        fluid(maxWidth: 1130, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
      }
    }
  }
`
